import { CheckCircleOutline, HighlightOff } from '@mui/icons-material'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import Loading from 'components/molecules/Loading'
import { useAtom } from 'jotai'
import { useEffect, useMemo, useState } from 'react'
import { alertAtom } from 'stores'
import { getUserRolesList } from 'stores/auth'
import { UserRole } from 'types/user'

const RolesMatrixGrid = (): JSX.Element => {
  const [rowData, setRowData] = useState<any[]>([])
  const [columnDefs, setColumnDefs] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [rolesData, setRolesData] = useState<UserRole[]>([])
  const [, setAlert] = useAtom(alertAtom)

  useEffect(() => {
    getRolesList()
  }, [])

  const getRolesList = (): void => {
    setLoading(true)
    getUserRolesList()
      .then(async response => {
        setRolesData(response.data)
        setLoading(false)
      })
      .catch(() => {
        setAlert({
          show: true,
          type: 'error',
          message: 'Failed to load roles list',
          autoHideDuration: 2000,
        })
        setLoading(false)
      })
  }

  useEffect(() => {
    if (rolesData && rolesData.length) {
      const columns = mapRolesToHeaders(rolesData)
      const { rows } = generateTableData(rolesData)
      setColumnDefs(columns)
      setRowData(transformData(rows))
    }
  }, [rolesData])

  const mapRolesToHeaders = (roles: any): Promise<any> => {
    const columns = roles.map((role: any) => {
      return {
        headerName: role.roleName.includes(':')
          ? role.roleName.split(':')[0]
          : role.roleName,
        children: [
          {
            headerName: 'View',
            field: role.roleName + '.View',
            cellStyle: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
            cellRenderer: (params: ICellRendererParams): JSX.Element => {
              if (params.value && params.value === 'Yes') {
                return <CheckCircleOutline color="primary" />
              }

              return <HighlightOff color="error" />
            },
            width: 92,
          },
          {
            headerName: 'Create',
            field: role.roleName + '.Create',
            cellStyle: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
            cellRenderer: (params: ICellRendererParams): JSX.Element => {
              if (params.value && params.value === 'Yes') {
                return <CheckCircleOutline color="primary" />
              }

              return <HighlightOff color="error" />
            },
            width: 92,
          },
        ],
      }
    })

    columns.unshift({
      sortable: false,
      suppressMovable: true,
      resizable: true,
      pinned: 'left',
      headerName: 'Permissions',
      field: 'permissions',
    })
    return columns
  }

  const generateTableData = (roles: any[]): any => {
    const rows: any = {}
    const permissions = new Set()

    // Loop through each role to extract permission data
    roles.forEach(role => {
      const roleName = role.roleName

      role.permissions.forEach((permissionObj: any) => {
        const permission = permissionObj.permission
        if (permission) {
          const [scope] = permission.split(':').slice(1)
          const action = permission.split(':').pop()
          const key = `${scope}`
          permissions.add(key)

          if (!rows[key]) {
            rows[key] = {}
          }

          if (!rows[key][roleName]) {
            rows[key][roleName] = { View: 'No', Create: 'No' }
          }

          if (
            action === 'OrgRead' ||
            action === 'AllRead' ||
            action === 'Read'
          ) {
            rows[key][roleName].View = 'Yes'
          } else if (
            action === 'OrgWrite' ||
            action === 'AllCreate' ||
            action === 'Create'
          ) {
            rows[key][roleName].Create = 'Yes'
          }
        }
      })
    })

    const rowArray = Array.from(permissions).map((permission: any) => {
      return {
        [permission]: rows[permission],
      }
    })

    return {
      rows: rowArray,
    }
  }

  const transformData = (data: any[]): any => {
    return data.map((item: any) => {
      const [permissionKey, permissionValues] = Object.entries(item)[0]

      const transformedItem: any = { permissions: permissionKey }
      if (permissionValues && typeof permissionValues === 'object') {
        for (const [key, value] of Object.entries(permissionValues)) {
          transformedItem[key] = value
        }
      }
      return transformedItem
    })
  }

  const getRowStyle = (params: any): any => {
    if (params.data.adObjectId === null) {
      return { background: '#ff586e1c', cursor: 'not-allowed' }
    }
  }

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
      filter: true,
      suppressMovable: true,
    }
  }, [])

  return (
    <div className="ag-theme-alpine" style={{ height: 520, width: '100%' }}>
      {!loading ? (
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          getRowStyle={getRowStyle}
          defaultColDef={defaultColDef}
          columnHoverHighlight={true}
        />
      ) : (
        <Loading height="400px" message="Loading Roles..." />
      )}
    </div>
  )
}
export default RolesMatrixGrid
